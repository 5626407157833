<template>
  <div>
    <el-card class="box-card" ref="card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" v-if="false">
            <el-form-item label="公司名称">
              <el-input placeholder="公司名称" v-model="formInline.user"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">查询</el-button>
            </el-form-item>
          </el-form>
          <div class="grid-content bg-purple" id="clientDiv">
            <el-scrollbar height="700px !important">
              <el-input v-model="clientFilterText" :placeholder="$t('device.input_keywords')"/>
              <el-tree
                  :data="clientData"
                  :default-expand-all="true"
                  :props="defaultProps"
                  @check="handleNodeClick"
                  :check-strictly="true"
                  highlight-current
                  node-key="id"
                  ref="tree"
                  show-checkbox
                  :filter-node-method="clientFilterNode"
              >
                <template v-slot="{node,data}">
                  <div class="custom-tree-node" @mouseleave="onleave()">
                    <div @mouseover="onover(node.id)">
                      <i :class="data.icon" class @click.stop="isShow(node)"></i>
                      {{ node.data.name }}
                    </div>
                    <div style="display:block;z-index:9999;">
                      <span class="action_section" v-if="ishover === node.id">
                        <span @click="newIncrease(node)">
                          <el-icon>
                            <plus/>
                          </el-icon>
                        </span>
                        <span @click="editClient(node)">
                          <el-icon>
                            <edit/>
                          </el-icon>
                        </span>
                        <span @click="deleteData(node)">
                          <el-icon>
                            <delete/>
                          </el-icon>
                        </span>
                      </span>
                    </div>
                  </div>
                </template>
              </el-tree>
            </el-scrollbar>
            <el-dialog center :title="$t('device.add_user')" top="15vh" v-model="addClientDialog" width="40%">
              <el-form :model="clientForm" ref="clientForm" label-width="100px">
                <el-form-item
                    :rules="[{ required: true, message: $t('device.company_empty')}]"
                    :label="$t('device.company_name')"
                    prop="name"
                >
                  <el-input autocomplete="off" v-model="clientForm.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('device.parent_customer')" prop="topClient" v-show="clientShow">
                  <el-input autocomplete="off" disabled v-model="clientForm.topClient"></el-input>
                </el-form-item>
                <!-- <el-form-item label="上级客户" prop="topClient" v-if="clientForm.id==''" v-show="clientShow">
                  <el-input autocomplete="off" disabled="true" v-model="clientForm.topClient"></el-input>
                </el-form-item>-->
                <el-form-item :label="$t('device.company_logo')" prop="imageList">
                  <!-- element 上传图片按钮 -->
                  <el-upload
                      class="upload-img"
                      :action="uploadUrl"
                      ref="upload"
                      :auto-upload="false"
                      :show-file-list="true"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      limit="1"
                      :on-error="onError"
                      :on-change="handleChangeUpload"
                      :class="{hide:hideUpload}"
                      :file-list="fileLists"
                  >
                    <el-icon :size="25">
                      <plus/>
                    </el-icon>
                    <!-- <div class="el-upload__text">点击上传</div>
                    <div class="el-upload__tip">支持绝大多数图片格式，单张图片最大支持5MB</div>-->
                  </el-upload>
                  <el-dialog v-model="logoDialog">
                    <img
                        style="width:400px;height:120px;margin:0 auto;display:block"
                        :src="clientForm.logo"
                        alt
                    />
                  </el-dialog>
                </el-form-item>
                <div style="text-align:center;font-size:12px;">（{{ $t('device.size_desc') }}）</div>
                <el-form-item :label="$t('device.data_push')">
                  <el-radio-group v-model="clientForm.push_status">
                    <el-radio :label="0">{{ $t('device.close') }}</el-radio>
                    <el-radio :label="1">{{ $t('device.testing') }}</el-radio>
                    <el-radio :label="2">{{ $t('device.official') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item :label="$t('device.testing')">
                  <el-input autocomplete="off" v-model="clientForm.push_testing"
                            :placeholder="$t('device.input_testing')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('device.official')">
                  <el-input autocomplete="off" v-model="clientForm.push_production"
                            :placeholder="$t('device.input_official')"></el-input>
                </el-form-item>
              </el-form>
              <template #footer>
                <span class="dialog-footer">
                  <el-button @click="addClientDialog = false">{{ $t('device.cancel') }}</el-button>
                  <el-button @click="addClientConfirm" type="primary">{{ $t('device.confirm') }}</el-button>
                </span>
              </template>
            </el-dialog>
            <el-dialog :title="$t('device.image_cropping')" v-model="dialogVisible" append-to-body center>
              <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                  <vueCropper
                      ref="cropper"
                      :img="option.img"
                      :outputSize="option.size"
                      :outputType="option.outputType"
                      :info="option.info"
                      :full="option.full"
                      :canMove="option.canMove"
                      :canMoveBox="option.canMoveBox"
                      :original="option.original"
                      :autoCrop="option.autoCrop"
                      :fixed="option.fixed"
                      :fixedNumber="option.fixedNumber"
                      :centerBox="option.centerBox"
                      :infoTrue="option.infoTrue"
                      :fixedBox="option.fixedBox"
                      :autoCropWidth="option.autoCropWidth"
                      :autoCropHeight="option.autoCropHeight"
                  />
                </div>
              </div>
              <div class="action-box"></div>
              <div
                  :v-slot="footer"
                  class="dialog-footer"
                  style="text-align:center;margin-top:20px;"
              >
                <el-button @click="dialogVisible = false">{{ $t('device.cancel') }}</el-button>
                <el-button type="primary" @click="finish" :loading="loadings">{{ $t('device.confirm') }}</el-button>
              </div>
            </el-dialog>

            <!--            <el-dialog center title="创建管理员" top="30vh" v-model="addUserDialog" width="30%">-->
            <!--              <el-form :model="ClientUserForm" ref="ClientUserForm">-->
            <!--                <el-form-item-->
            <!--                    :rules="[{ required: true, message: '公司名称不能为空'}]"-->
            <!--                    label="用户名"-->
            <!--                    label-width="100px"-->
            <!--                    prop="account"-->
            <!--                >-->
            <!--                  <el-input autocomplete="off" v-model="ClientUserForm.account"></el-input>-->
            <!--                </el-form-item>-->
            <!--                <el-form-item-->
            <!--                    :rules="[{ required: true, message: '密码不能为空'}]"-->
            <!--                    label="密码"-->
            <!--                    label-width="100px"-->
            <!--                    prop="password"-->
            <!--                >-->
            <!--                  <el-input autocomplete="off" v-model="ClientUserForm.password"></el-input>-->
            <!--                </el-form-item>-->
            <!--              </el-form>-->
            <!--              <template #footer>-->
            <!--                <span class="dialog-footer">-->
            <!--                  <el-button @click="addUserDialog = false">取 消</el-button>-->
            <!--                  <el-button @click="addUserConfirm" type="primary">确 定</el-button>-->
            <!--                </span>-->
            <!--              </template>-->
            <!--            </el-dialog>-->
          </div>
        </el-col>
        <el-col :span="18">
          <el-tabs v-model="activeName">
            <el-tab-pane :label="$t('device.devices')" name="first">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="设备IMEI">
                  <el-input :rows="1" type="textarea" placeholder="设备IMEI" v-model="formInline.imei"></el-input>
                </el-form-item>
                <el-form-item :label="$t('index.device_type')" prop="type">
                  <el-select v-model="formInline.type" :placeholder="$t('index.device_type')" clearable>
                    <el-option
                        v-for="item in deviceType"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('device.device_status')" prop="is_online">
                  <el-select v-model="formInline.is_online" :placeholder="$t('device.device_status')" clearable>
                    <el-option :label="$t('index.online')" value="1"></el-option>
                    <el-option :label="$t('index.offline')" value="0"></el-option>
                    <el-option :label="$t('index.stripping_outline')" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button @click="devicedListSearch" type="primary">{{ $t('device.inquire') }}</el-button>
                  <el-button @click="handleReset" type="primary">{{ $t('device.reset') }}</el-button>
                  <el-button type="primary" @click="handleExport">{{ $t('device.export') }}</el-button>
                </el-form-item>
              </el-form>
              <div class="grid-content bg-purple">
                <el-button @click="addDevice" type="primary" v-if="$store.state.pageAction.indexOf('device.add')> -1">
                  {{ $t('device.add') }}
                </el-button>
                <el-button @click="batchAddDevice" type="primary"
                           v-if="$store.state.pageAction.indexOf('device.batch_add')> -1">{{ $t('device.batch_add') }}
                </el-button>
                <el-button
                    :disabled="allotform.imei.length===0"
                    @click="allotClient"
                    type="primary"
                    v-if="$store.state.pageAction.indexOf('device.assign')> -1"
                >{{ $t('device.assign') }}
                </el-button>
                <el-button :disabled="removeImeiArr.length===0" @click="batchDel" type="primary"
                           v-if="$store.state.pageAction.indexOf('device.delete')> -1">{{
                    $t('device.delete')
                  }}
                </el-button>
                <div v-show="is_search">
                  {{ $t('device.co_search') + search_total }}, {{ $t('device.device_found') + deviceTotal }}
                  <span v-show="search_total !== deviceTotal">
                    ，{{ $t('device.view') }}
                    <el-button @click="noSearchDialog = true" size="small" type="text">{{
                        $t('device.not_found_device')
                      }}</el-button>
                  </span>
                </div>
                <el-table
                    :data="deviceData"
                    :default-sort="{prop: 'date', order: 'descending'}"
                    @sort-change="sortChange"
                    @selection-change="handleSelect"
                    border
                    stripe
                    style="width: 100%"
                >
                  <el-table-column type="selection" width="55" fixed="left"></el-table-column>
                  <el-table-column :label="$t('index.device')" prop="imei" width="120"></el-table-column>
                  <el-table-column :label="$t('index.device_type')" prop="type" width="200"></el-table-column>
                  <el-table-column :label="$t('index.battery_level')" prop="volt" width="200"
                                   sortable="custom"></el-table-column>
                  <el-table-column :label="$t('device.device_status')" prop="online" width="200"></el-table-column>
                  <el-table-column :label="$t('device.vesting')" width="200" prop="name"></el-table-column>
                  <el-table-column :label="$t('device.creation_time')" width="200" prop="create_at"
                                   sortable="custom"></el-table-column>
                  <el-table-column :label="$t('device.last_comm_time')" width="200" prop="update_at"
                                   sortable="custom"></el-table-column>
                  <el-table-column :label="$t('device.operating')" width="150" fixed="right">
                    <template #default="scope">
                      <el-button
                          :disabled="(scope.row.is_online!=1||scope.row.type == 'CAT1-E10S')&& [15,17].indexOf(scope.row.type_id)<0"
                          @click="setParam(scope.row)"
                          size="small"
                          type="text"
                          v-if="$store.state.pageAction.indexOf('device.parameter')> -1"
                      >{{ $t('device.parameter') }}
                      </el-button>
                      <el-button type="text" @click="editParam(scope.row)" size="small"
                                 v-if="$store.state.pageAction.indexOf('device.edit')> -1">{{
                          $t('device.edit')
                        }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                    :current-page="formInline.page"
                    :page-size="formInline.size"
                    :page-sizes="[10, 20, 50]"
                    :total="deviceTotal"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    fixed="right"
                    layout="total, sizes, prev, pager, next, jumper"
                ></el-pagination>
                <el-dialog center :title="$t('device.add_devices')" top="30vh" v-model="addDialog" width="30%">
                  <el-form :model="addform" ref="addform">
                    <el-form-item
                        :rules="[{ required: true, message: $t('device.device_empty')}]"
                        :label="$t('index.device')"
                        label-width="100px"
                        prop="imei"
                        disabled
                    >
                      <el-input
                          autocomplete="off"
                          v-model="addform.imei"
                          :disabled="imeiDesabled"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                        :rules="[{ required: true, message: $t('device.device_type_empty')}]"
                        :label="$t('index.device_type')"
                        label-width="100px"
                        prop="type_id"
                    >
                      <el-select clearable :placeholder="$t('index.device_type')" v-model="addform.type_id">
                        <el-option
                            :key="item.value"
                            :label="item.text"
                            :value="item.value"
                            v-for="item in deviceType"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="addDialog = false">{{ $t('device.cancel') }}</el-button>
                      <el-button @click="addConfirm" type="primary">{{ $t('device.confirm') }}</el-button>
                    </span>
                  </template>
                </el-dialog>
                <el-dialog center :title="$t('device.batch_add')" top="30vh" v-model="batchDialog" width="30%">
                  <el-form ref="batchform">
                    <el-form-item
                        :label="$t('device.import_template')"
                        label-width="100px"
                    >
                      <el-link
                          href="https://adminapi.yztc2022.com/xlsx/手环上传模板.xlsx"
                          :underline="false"
                          style="margin-left:15px"
                          target="_blank"
                      >
                        <el-button size="small" type="primary">{{ $t('device.download') }}</el-button>
                      </el-link>
                    </el-form-item>
                    <el-form-item
                        :rules="[{ required: true, message: $t('device.input_import'),trigger:'blur'}]"
                        :label="$t('device.import_file')"
                        label-width="100px"
                    >
                      <el-upload
                          ref="excel"
                          :headers="excelHeaders"
                          :auto-upload="false"
                          :limit="1"
                          action="/v1/device/excel_batch_upload"
                          :on-success="handleExcelSuccess"
                      >
                        <!--                      <el-icon :size="10">-->
                        <!--                        <plus />-->
                        <!--                      </el-icon>-->
                        <div class="el-upload__text">{{ $t('device.upload') }}</div>
                        <div class="el-upload__tip">{{ $t('device.only_files') }}</div>
                      </el-upload>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="batchDialog = false">{{ $t('device.close') }}</el-button>
                      <el-button @click="batchAddConfirm" :loading="loading" type="primary">{{
                          $t('device.confirm')
                        }}</el-button>
                    </span>
                  </template>
                </el-dialog>
                <el-dialog center :title="$t('device.tips')" v-model="batchTip" width="30%">
                  <p>{{
                      $t('device.success_import') + successNum
                    }}，{{ $t('device.error_import') + errorNum + $t('device.not_import') }}</p>
                  <p>{{ $t('device.import_tip') }}</p>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="batchTip = false">取 消</el-button>
                      <el-link
                          :href="filePath"
                          :underline="false"
                          style="margin-left:15px"
                          target="_blank"
                      >
                        <el-button @click="centerDialogVisible = false" type="primary">{{
                            $t('device.confirm')
                          }}</el-button>
                      </el-link>
                    </span>
                  </template>
                </el-dialog>
                <el-dialog center :title="$t('device.assign')" top="30vh" v-model="allotDialog" width="30%">
                  <el-form :model="allotform" ref="allotform">
                    <el-form-item
                        :rules="[{ required: true, message: $t('device.customer_empty')}]"
                        :label="$t('device.select_customer')"
                        label-width="100px"
                        prop="client"
                    >
                      <el-cascader
                          :options="options"
                          :placeholder="$t('device.select_customer')"
                          v-model="allotform.client"
                      ></el-cascader>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="allotDialog = false">{{ $t('device.cancel') }}</el-button>
                      <el-button @click="handleAllot" type="primary">{{ $t('device.confirm') }}</el-button>
                    </span>
                  </template>
                </el-dialog>
                <el-dialog center :title="$t('device.device_not_found')" top="20vh" v-model="noSearchDialog"
                           width="40%">
                  <div style="text-align:center;max-height:400px;overflow:auto;" class="copylist">
                    <div
                        v-for="imei in no_result"
                        :key="imei"
                        class="imei"
                        style="line-height:25px;"
                    >{{ imei }}
                    </div>
                  </div>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="keepSerach">{{ $t('device.continue_search') }}</el-button>
                      <el-button @click="copyImei" type="primary">{{ $t('device.copy_all') }}</el-button>
                    </span>
                  </template>
                </el-dialog>
                <el-dialog :title="$t('device.device_params')+'(NB)'" top="30vh" v-model="setnbDialog" width="30%">
                  <el-form :model="setNbForm">
                    <el-form-item :label="$t('device.sleep_mode')" label-width="120px">
                      <el-radio-group v-model="setting.sleep_status">
                        <el-radio :label="1">{{ $t('device.close') }}</el-radio>
                        <el-radio :label="2">{{ $t('device.open') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="$t('device.night_mode')" label-width="120px">
                      <el-radio-group v-model="setting.night_status">
                        <el-radio :label="1">{{ $t('device.close') }}</el-radio>
                        <el-radio :label="2">{{ $t('device.open') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <!--                    <el-form-item label="定位开启" label-width="120px">-->
                    <!--                      <el-radio-group v-model="setting.gps_status">-->
                    <!--                        <el-radio :label="1">开启</el-radio>-->
                    <!--                        <el-radio :label="0">关闭</el-radio>-->
                    <!--                      </el-radio-group>-->
                    <!--                    </el-form-item>-->
                    <el-form-item :label="$t('device.data_upload_interval')" label-width="120px" prop="content">
                      <div style="display:flex;">
                        <el-input
                            :placeholder="$t('device.interval_time')"
                            style="width:120px;"
                            v-model="setting.gps_interval"
                        ></el-input>
                        <span style="margin:0px 10px;">{{ $t('device.minute') }}</span>
                      </div>
                      <i style="color: red">{{ $t('device.minutes_error') }}</i>
                    </el-form-item>
                  </el-form>
                  <p
                      style="text-align:center;font-size:12px;color:#999999;margin-top:30px;"
                  >{{ $t('device.nb_note') }}</p>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="setnbDialog = false">{{ $t('device.cancel') }}</el-button>
                      <el-button @click="handleSendCommand(-1)" type="primary">{{ $t('device.confirm') }}</el-button>
                    </span>
                  </template>
                </el-dialog>
                <el-dialog :title="$t('device.device_params')+'('+$t('device.positioner')+')'" top="5vh"
                           v-model="locatorDialog" width="70%">
                  <h2>{{ $t('index.device') }} : {{ command.imei }}</h2>
                  <div class="warnmes">{{ $t('device.setting_note') }}</div>
                  <el-tabs :tab-position="tabPosition" type="border-card">
                    <template :key="index" v-for="(item,index) in setting">
                      <el-tab-pane :label="item.title">
                        <template v-if="item.id===1">
                          <p>{{ $t('device.second_tip') }}</p>
                          <el-divider></el-divider>
                          <el-form-item :label="$t('device.data_upload_interval')" label-width="120px">
                            <div style="display:flex;">
                              <el-input
                                  :placeholder="$t('device.interval_time')"
                                  style="width:120px;"
                                  v-model="item.locator_interval"
                              ></el-input>
                              <span style="margin:0px 10px;">{{ $t('device.second') }}</span>
                            </div>
                          </el-form-item>
                        </template>
                        <template v-else-if="item.id===2">
                          <p>{{ $t('device.position_note') }}</p>
                          <el-divider></el-divider>
                          <el-form-item :label="$t('device.position_mode')" label-width="120px">
                            <el-radio-group v-model="item.locator_type">
                              <el-radio :label="2">{{ $t('device.wifi_priority') }}</el-radio>
                              <el-radio :label="3">{{ $t('device.gps_priority') }}</el-radio>
                              <el-radio :label="1">WIFI</el-radio>
                              <el-radio :label="0">GPS</el-radio>
                            </el-radio-group>
                          </el-form-item>
                        </template>
                        <template v-else-if="item.id===3">
                          <p>{{ $t('device.position_time') }}</p>
                          <el-divider></el-divider>
                          <el-form-item :label="$t('device.position_time')" label-width="120px">
                            <div style="display:flex;">
                              <el-input
                                  :placeholder="$t('device.interval_time')"
                                  style="width:120px;"
                                  v-model="item.locator_time"
                              ></el-input>
                              <span style="margin:0px 10px;">{{ $t('device.second') }}</span>
                            </div>
                          </el-form-item>
                        </template>
                        <template v-else-if="item.id===4">
                          <p>{{ $t('device.active_config') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.status')">
                              <el-switch
                                  :active-value="1"
                                  :inactive-value="0"
                                  active-color="#13ce66"
                                  :active-text="$t('device.open')"
                                  :inactive-text="$t('device.close')"
                                  v-model="item.locator_interval_status"
                              ></el-switch>
                            </el-form-item>
                            <el-form-item :label="$t('device.start_time')">
                              <el-input
                                  :placeholder="$t('device.time_example')+'07:00'"
                                  v-model="item.locator_interval_start"
                              ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('device.end_time')">
                              <el-input
                                  :placeholder="$t('device.time_example')+'07:00'"
                                  v-model="item.locator_interval_end"
                              ></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===5">
                          <p>{{ $t('device.prompt_config') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.remind_type')">
                              <el-radio-group v-model="item.locator_remind_type">
                                <el-radio :label="1">{{ $t('device.one_sound') }}</el-radio>
                                <el-radio :label="2">{{ $t('device.keep_sound') }}</el-radio>
                              </el-radio-group>
                            </el-form-item>
                            <el-form-item :label="$t('device.audio_type')">
                              <el-radio-group v-model="item.locator_remind_media">
                                <el-radio :label="1">{{ $t('device.urgent_sound') }}</el-radio>
                                <el-radio :label="2">{{ $t('device.smooth_sound') }}</el-radio>
                                <el-radio :label="3">{{ $t('device.vibration') }}</el-radio>
                              </el-radio-group>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===6">
                          <p>{{ $t('device.time_alarm') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.status')">
                              <el-switch
                                  :active-value="1"
                                  :inactive-value="0"
                                  active-color="#13ce66"
                                  :active-text="$t('device.open')"
                                  :inactive-text="$t('device.close')"
                                  v-model="item.locator_alarm_status"
                              ></el-switch>
                            </el-form-item>
                            <el-form-item :label="$t('device.remind_type')">
                              <el-radio-group v-model="item.locator_alarm_type">
                                <el-radio :label="1">{{ $t('device.one_sound') }}</el-radio>
                                <el-radio :label="2">{{ $t('device.keep_sound') }}</el-radio>
                              </el-radio-group>
                            </el-form-item>
                            <el-form-item :label="$t('device.audio_type')">
                              <el-radio-group v-model="item.locator_alarm_media">
                                <el-radio :label="1">{{ $t('device.urgent_sound') }}</el-radio>
                                <el-radio :label="2">{{ $t('device.smooth_sound') }}</el-radio>
                                <el-radio :label="3">{{ $t('device.vibration') }}</el-radio>
                              </el-radio-group>
                            </el-form-item>
                            <el-form-item :label="$t('device.time')">
                              <el-input
                                  :placeholder="$t('device.time_example')+'07:00'"
                                  v-model="item.locator_alarm_time"
                              ></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <el-divider></el-divider>
                        <el-button @click="handleSendCommand(index)" type="primary">{{ $t('device.send') }}</el-button>
                      </el-tab-pane>
                    </template>
                  </el-tabs>
                </el-dialog>
                <el-dialog :title="$t('device.device_params')+'(01H)'" top="5vh" v-model="heartDialog" width="70%">
                  <h2>{{ $t('index.device') }} : {{ command.imei }}</h2>
                  <div class="warnmes">{{ $t('device.setting_note') }}</div>
                  <el-tabs :tab-position="tabPosition" type="border-card">
                    <template :key="index" v-for="(item,index) in setting">
                      <el-tab-pane :label="item.title">
                        <template v-if="item.id===1">
                          <p>以分钟为单位下发间隔，实时上传心率间隔范围：1-10；自动关闭时间间隔：1-3600</p>
                          <el-divider></el-divider>
                          <el-form-item label="心率上传间隔" label-width="120px">
                            <div style="display:flex;">
                              <el-input
                                  placeholder="间隔时间"
                                  style="width:120px;"
                                  v-model="item.heart_now_time"
                              ></el-input>
                              <span style="margin:0px 10px;">分钟</span>
                            </div>
                          </el-form-item>
                          <el-form-item label="自动关闭间隔" label-width="120px">
                            <div style="display:flex;">
                              <el-input
                                  placeholder="间隔时间"
                                  style="width:120px;"
                                  v-model="item.heart_auto_close"
                              ></el-input>
                              <span style="margin:0px 10px;">分钟</span>
                            </div>
                          </el-form-item>
                        </template>
                        <template v-else-if="item.id===2">
                          <p>{{ $t('device.heart_upload') }}</p>
                          <el-divider></el-divider>
                          <el-form-item :label="$t('device.allow_time')">
                            <el-input
                                :placeholder="$t('device.time_example')+'07:00'"
                                v-model="item.heart_allow_time"
                            ></el-input>
                          </el-form-item>
                          <el-form-item :label="$t('device.prohibit_time')">
                            <el-input
                                :placeholder="$t('device.time_example')+'22:00'"
                                v-model="item.heart_no_time"
                            ></el-input>
                          </el-form-item>
                        </template>
                        <template v-else-if="item.id===3">
                          <p>{{ $t('device.heart_threshold') }}</p>
                          <el-divider></el-divider>
                          <el-form-item :label="$t('device.min_threshold')" label-width="120px">
                            <div style="display:flex;">
                              <el-input
                                  :placeholder="$t('device.input_min_threshold')"
                                  style="width:120px;"
                                  v-model="item.heart_warn_min"
                              ></el-input>
                            </div>
                          </el-form-item>
                          <el-form-item :label="$t('device.max_threshold')" label-width="120px">
                            <div style="display:flex;">
                              <el-input
                                  :placeholder="$t('device.input_max_threshold')"
                                  style="width:120px;"
                                  v-model="item.heart_warn_max"
                              ></el-input>
                            </div>
                          </el-form-item>
                        </template>
                        <template v-else-if="item.id===4">
                          <p>{{ $t('device.distribute_note') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.measure_interval')">
                              <el-input
                                  :placeholder="$t('device.measure_interval_error')"
                                  v-model="item.heart_measure"
                              ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('device.upload_interval')">
                              <el-input
                                  :placeholder="$t('device.upload_interval_error')"
                                  v-model="item.heart_time"
                              ></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===5">
                          <p>{{ $t('device.night_mode') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.status')">
                              <el-switch
                                  :active-value="2"
                                  :inactive-value="1"
                                  active-color="#13ce66"
                                  :active-text="$t('device.open')"
                                  :inactive-text="$t('device.close')"
                                  v-model="item.heart_night"
                              ></el-switch>
                            </el-form-item>
                          </el-form>
                        </template>
                        <el-divider></el-divider>
                        <el-button @click="handleSendCommand(index)" type="primary">{{ $t('device.send') }}</el-button>
                      </el-tab-pane>
                    </template>
                  </el-tabs>
                </el-dialog>
                <el-dialog :title="$t('device.device_params')+'(01G1)'" top="5vh" v-model="bracelet01G1Dialog"
                           width="70%">
                  <h2>{{ $t('index.device') }} : {{ command.imei }}</h2>
                  <div class="warnmes">{{ $t('device.setting_note') }}</div>
                  <el-tabs :tab-position="tabPosition" type="border-card">
                    <template :key="index" v-for="(item,index) in setting">
                      <el-tab-pane :label="item.title">
                        <template v-if="item.id===1">
                          <p>{{ $t('device.sleep_mode') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.status')">
                              <el-switch
                                  :active-value="1"
                                  :inactive-value="2"
                                  active-color="#13ce66"
                                  :active-text="$t('device.open')"
                                  :inactive-text="$t('device.close')"
                                  v-model="item.sleep_status"
                              ></el-switch>
                            </el-form-item>
                            <el-form-item :label="$t('device.start_time')">
                              <el-time-select
                                  v-model="item.sleep_start_time"
                                  start="00:00"
                                  step="00:15"
                                  end="23:45"
                                  :placeholder="$t('device.select_time')"
                              />
                            </el-form-item>
                            <el-form-item :label="$t('device.end_time')">
                              <el-time-select
                                  v-model="item.sleep_end_time"
                                  start="00:00"
                                  step="00:15"
                                  end="23:45"
                                  :placeholder="$t('device.select_time')"
                              />
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===2">
                          <p>{{ $t('device.night_mode') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.switch')">
                              <el-switch
                                  :active-value="2"
                                  :inactive-value="1"
                                  active-color="#13ce66"
                                  :active-text="$t('device.open')"
                                  :inactive-text="$t('device.close')"
                                  v-model="item.night_status"
                              ></el-switch>
                            </el-form-item>
                            <el-form-item :label="$t('device.start_time')">
                              <el-time-select
                                  v-model="item.night_start_time"
                                  start="00:00"
                                  step="00:15"
                                  end="23:45"
                                  :placeholder="$t('device.select_time')"
                              />
                            </el-form-item>
                            <el-form-item :label="$t('device.end_time')">
                              <el-time-select
                                  v-model="item.night_end_time"
                                  start="00:00"
                                  step="00:15"
                                  end="23:45"
                                  :placeholder="$t('device.select_time')"
                              />
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===3">
                          <p>{{ $t('device.interval_minute') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.upload_interval')">
                              <el-input
                                  :placeholder="$t('device.upload_interval_error')"
                                  v-model="item.gps_interval"
                              ></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===4">
                          <p>{{ $t('device.wifi_note') }}</p>
                          <el-divider></el-divider>
                          <el-form-item :label="$t('device.position_mode')" label-width="120px">
                            <el-radio-group v-model="item.locator_type">
                              <el-radio :label="1">{{ $t('device.wifi_priority') }}</el-radio>
                              <el-radio :label="2">{{ $t('device.only_gps') }}</el-radio>
                            </el-radio-group>
                          </el-form-item>
                        </template>
                        <el-divider></el-divider>
                        <el-button @click="handleSendCommand(index)" type="primary">{{ $t('device.send') }}</el-button>
                      </el-tab-pane>
                    </template>
                  </el-tabs>
                </el-dialog>
                <el-dialog :title="$t('device.device_params')+'(4G)'" top="5vh" v-model="setsgDialog" width="70%">
                  <h2>{{ $t('index.device') }} : {{ command.imei }}</h2>
                  <div class="warnmes">{{ $t('device.setting_note') }}</div>
                  <el-tabs :tab-position="tabPosition" type="border-card">
                    <template :key="index" v-for="(item,index) in setting">
                      <el-tab-pane :label="item.title">
                        <template v-if="item.id===1">
                          <p>{{ $t('device.sos_note') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.sos')+' 1'">
                              <el-input type="number" v-model="item.value.sos1"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('device.sos')+' 2'">
                              <el-input type="number" v-model="item.value.sos2"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('device.sos')+' 3'">
                              <el-input type="number" v-model="item.value.sos3"></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===2">
                          <p>{{ $t('device.reboot') }}</p>
                        </template>
                        <template v-else-if="item.id===3">
                          <p>{{ $t('device.restore') }}</p>
                        </template>
                        <template v-else-if="item.id===4">
                          <p>{{ $t('device.instant') }}</p>
                        </template>
                        <template v-else-if="item.id===5">
                          <p>{{ $t('device.remote') }}</p>
                        </template>
                        <template v-else-if="item.id===6">
                          <p>{{ $t('device.low_battery_alarm') }}</p>
                          <el-divider></el-divider>
                          <el-switch
                              :active-value="1"
                              :inactive-value="0"
                              active-color="#13ce66"
                              :active-text="$t('device.open')"
                              :inactive-text="$t('device.close')"
                              v-model="item.value"
                          ></el-switch>
                        </template>
                        <template v-else-if="item.id===7">
                          <p>
                            {{ $t('device.alarm_time_note') }}
                          </p>
                          <el-divider></el-divider>
                          <div>
                            <el-form :inline="true" ref="form">
                              <template :key="key" v-for="(value,key) in item.value">
                                <div>
                                  <el-form-item :label="$t('device.time') + (key + 1)">
                                    <el-input :placeholder="$t('device.time_example')+' 09:00'"
                                              v-model="value.time"></el-input>
                                  </el-form-item>
                                  <el-form-item :label="$t('device.switch')">
                                    <el-switch
                                        active-color="#13ce66"
                                        active-value="1"
                                        inactive-value="0"
                                        v-model="value.status"
                                    ></el-switch>
                                  </el-form-item>
                                  <el-form-item :label="$t('device.cycle')">
                                    <el-checkbox
                                        :checked="value.day[0]==1"
                                        false-label="0"
                                        true-label="1"
                                        v-model="value.day[0]"
                                    >{{ $t('device.sunday') }}
                                    </el-checkbox>
                                    <el-checkbox
                                        :checked="value.day[1]==1"
                                        false-label="0"
                                        true-label="1"
                                        v-model="value.day[1]"
                                    >{{ $t('device.monday') }}
                                    </el-checkbox>
                                    <el-checkbox
                                        :checked="value.day[2]==1"
                                        false-label="0"
                                        true-label="1"
                                        v-model="value.day[2]"
                                    >{{ $t('device.tuesday') }}
                                    </el-checkbox>
                                    <el-checkbox
                                        :checked="value.day[3]==1"
                                        false-label="0"
                                        true-label="1"
                                        v-model="value.day[3]"
                                    >{{ $t('device.wednesday') }}
                                    </el-checkbox>
                                    <el-checkbox
                                        :checked="value.day[4]==1"
                                        false-label="0"
                                        true-label="1"
                                        v-model="value.day[4]"
                                    >{{ $t('device.thursday') }}
                                    </el-checkbox>
                                    <el-checkbox
                                        :checked="value.day[5]==1"
                                        false-label="0"
                                        true-label="1"
                                        v-model="value.day[5]"
                                    >{{ $t('device.friday') }}
                                    </el-checkbox>
                                    <el-checkbox
                                        :checked="value.day[6]==1"
                                        false-label="0"
                                        true-label="1"
                                        v-model="value.day[6]"
                                    >{{ $t('device.saturday') }}
                                    </el-checkbox>
                                  </el-form-item>
                                </div>
                              </template>
                            </el-form>
                          </div>
                        </template>
                        <template v-else-if="item.id===8">
                          <p>{{ $t('device.step_trigger') }}</p>
                          <el-divider></el-divider>
                          <el-switch
                              :active-value="1"
                              :inactive-value="0"
                              active-color="#13ce66"
                              :active-text="$t('device.open')"
                              :inactive-text="$t('device.close')"
                              v-model="item.value"
                          ></el-switch>
                        </template>
                        <template v-else-if="item.id===9">
                          <p>{{ $t('device.second_tip') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.data_upload_interval')">
                              <el-input v-model="item.value"></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===10">
                          <p>{{ $t('device.phone_book') }}</p>
                          <el-divider></el-divider>
                          <div class="phoneList">
                            <el-form
                                :inline="true"
                                :key="key"
                                label-width="100px"
                                v-for="(value,key) in item.value"
                            >
                              <el-form-item :label="$t('device.name')+(key+1)">
                                <el-input v-model="value.name"></el-input>
                              </el-form-item>
                              <el-form-item :label="$t('device.number')+(key+1)">
                                <el-input v-model="value.number"></el-input>
                              </el-form-item>
                            </el-form>
                          </div>
                        </template>
                        <template v-else-if="item.id===11">
                          <p>{{ $t('device.time_example') }} 07:00</p>
                          <el-divider></el-divider>
                          <div class="phoneList">
                            <el-form
                                :inline="true"
                                :key="key"
                                label-width="100px"
                                v-for="(value,key) in item.value"
                            >
                              <el-form-item :label="$t('device.start_time')+(key+1)">
                                <el-input v-model="value.start"></el-input>
                              </el-form-item>
                              <el-form-item :label="$t('device.end_time')+(key+1)">
                                <el-input v-model="value.end"></el-input>
                              </el-form-item>
                            </el-form>
                          </div>
                        </template>
                        <template v-else-if="item.id===12">
                          <p>{{ $t('device.unit') }}: 300-65535</p>
                          <el-divider></el-divider>
                          <el-form :inline="true" ref="form">
                            <el-form-item>
                              <el-input :placeholder="$t('device.heart_interval')"
                                        v-model="item.value.interval"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('device.switch')">
                              <el-switch
                                  :active-value="1"
                                  :inactive-value="0"
                                  active-color="#13ce66"
                                  v-model="item.value.status"
                              ></el-switch>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===13">
                          <p>{{ $t('device.measure_heart') }}</p>
                        </template>
                        <template v-else-if="item.id===14">
                          <p>{{ $t('device.find') }}</p>
                        </template>
                        <template v-else-if="item.id===15">
                          <p>{{ $t('device.sleep_setting') }}</p>
                          <el-divider></el-divider>
                          <div class="phoneList">
                            <el-form-item>
                              <el-time-select
                                  v-model="item.value.start"
                                  start="00:00"
                                  step="00:30"
                                  end="24:00"
                                  :placeholder="$t('device.start_time')"
                              />
                            </el-form-item>
                            <el-form-item>
                              <el-time-select
                                  v-model="item.value.end"
                                  start="00:00"
                                  step="00:30"
                                  end="24:00"
                                  :placeholder="$t('device.end_time')"
                              />
                            </el-form-item>
                          </div>
                        </template>
                        <template v-else-if="item.id===16">
                          <p>{{ $t('device.temp_note') }}</p>
                        </template>
                        <template v-else-if="item.id===17">
                          <p>{{ $t('device.temp_interval') }}</p>
                          <div>
                            <el-form label-width="100px" ref="form">
                              <el-form-item :label="$t('device.switch')">
                                <el-switch
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    v-model="item.value.status"
                                ></el-switch>
                              </el-form-item>
                              <el-form-item :label="$t('device.data_upload_interval')">
                                <el-input
                                    :placeholder="$t('device.unit_hour')"
                                    v-model="item.value.interval"
                                ></el-input>
                              </el-form-item>
                            </el-form>
                          </div>
                        </template>
                        <template v-else-if="item.id===19">
                          <p>{{ $t('device.custom_instruction') }}</p>
                          <div>
                            <el-form label-width="100px" ref="form">
                              <el-form-item :label="$t('device.instruction_content')">
                                <el-input
                                    :placeholder="$t('device.instruction_error')"
                                    v-model="item.value"
                                ></el-input>
                              </el-form-item>
                            </el-form>
                          </div>
                        </template>
                        <template v-else-if="item.id===20">
                          <p>{{ $t('device.falldown_setting') }}</p>
                          <div>
                            <el-form label-width="100px" ref="form">
                              <el-form-item :label="$t('device.switch')">
                                <el-switch
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    v-model="item.value.fall_status"
                                ></el-switch>
                              </el-form-item>
                              <el-form-item :label="$t('device.call_emergency')">
                                <el-switch
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    v-model="item.value.fall_call"
                                ></el-switch>
                              </el-form-item>
                            </el-form>
                          </div>
                        </template>
                        <template v-else-if="item.id===21">
                          <p>{{ $t('device.falldown_note') }}</p>
                          <el-divider></el-divider>
                          <el-form :inline="true" ref="form">
                            <el-form-item :label="$t('device.sensitivity')">
                              <el-input :placeholder="$t('device.suggest')" type="number"
                                        v-model="item.value.fall_sensitivity"></el-input>
                            </el-form-item>
                            <!--                            <el-form-item label="灵敏度总档位">-->
                            <!--                              <el-input placeholder="输入建议8" type="number"-->
                            <!--                                        v-model="item.value.fall_sensitivity_total"></el-input>-->
                            <!--                            </el-form-item>-->
                          </el-form>
                        </template>
                        <el-divider></el-divider>
                        <el-button @click="handleSendCommand(index)" type="primary">{{ $t('device.send') }}</el-button>
                      </el-tab-pane>
                    </template>
                  </el-tabs>
                </el-dialog>
                <el-dialog :title="$t('device.device_params')" top="5vh" v-model="set4GLocatorDialog" width="70%">
                  <h2>{{ $t('index.device') }} : {{ command.imei }}</h2>
                  <div class="warnmes">{{ $t('device.setting_note') }}</div>
                  <el-tabs :tab-position="tabPosition" type="border-card">
                    <template :key="index" v-for="(item,index) in setting">
                      <el-tab-pane :label="item.title">
                        <template v-if="item.id===1">
                          <p>{{ $t('device.sos_note') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.sos')+' 1'">
                              <el-input type="number" v-model="item.value.sos1"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('device.sos')+' 2'">
                              <el-input type="number" v-model="item.value.sos2"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('device.sos')+' 3'">
                              <el-input type="number" v-model="item.value.sos3"></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===2">
                          <p>{{ $t('device.second_tip') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.data_upload_interval')">
                              <el-input v-model="item.value"></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===3">
                          <p>{{ $t('device.position_note') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.position_mode')">
                              <el-radio-group v-model="item.value">
                                <el-radio :label="2">{{ $t('device.wifi_priority') }}</el-radio>
                                <el-radio :label="3">{{ $t('device.gps_priority') }}</el-radio>
                                <el-radio :label="1">WIFI</el-radio>
                                <el-radio :label="0">GPS</el-radio>
                              </el-radio-group>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===4">
                          <p>{{ $t('device.gps_timeout_second') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.gps_timeout')">
                              <el-input v-model="item.value"></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===5">
                          <p>{{ $t('device.instant') }}</p>
                        </template>
                        <template v-else-if="item.id===6">
                          <p>{{ $t('device.reject_phone') }}</p>
                          <el-divider></el-divider>
                          <el-switch
                              :active-value="1"
                              :inactive-value="0"
                              active-color="#13ce66"
                              :active-text="$t('device.open')"
                              :inactive-text="$t('device.close')"
                              v-model="item.value"
                          ></el-switch>
                        </template>
                        <template v-else-if="item.id===7">
                          <p>{{ $t('device.timer_on_off') }}</p>
                          <el-divider></el-divider>
                          <div>
                            <el-form label-width="100px" ref="form">
                              <el-form-item :label="$t('device.switch')">
                                <el-switch
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    v-model="item.value.auto_status"
                                ></el-switch>
                              </el-form-item>
                              <el-form-item :label="$t('device.on_time')">
                                <el-input
                                    :placeholder="$t('device.time_example')+'07:00'"
                                    v-model="item.value.on_time"
                                ></el-input>
                              </el-form-item>
                              <el-form-item :label="$t('device.off_time')">
                                <el-input
                                    :placeholder="$t('device.time_example')+'23:00'"
                                    v-model="item.value.off_time"
                                ></el-input>
                              </el-form-item>
                            </el-form>
                          </div>
                        </template>
                        <el-divider></el-divider>
                        <el-button @click="handleSendCommand(index)" type="primary">{{ $t('device.send') }}</el-button>
                      </el-tab-pane>
                    </template>
                  </el-tabs>
                </el-dialog>
                <el-dialog :title="$t('device.device_params') +'('+$t('device.wifi_watch')+')'" top="5vh"
                           v-model="setWIFIWatchDialog" width="70%">
                  <h2>{{ $t('index.device') }} : {{ command.imei }}</h2>
                  <div class="warnmes">{{ $t('device.setting_note') }}</div>
                  <el-tabs :tab-position="tabPosition" type="border-card">
                    <template :key="index" v-for="(item,index) in setting">
                      <el-tab-pane :label="item.title">
                        <template v-if="item.id===1">
                          <p>{{ $t('device.second_tip') }}</p>
                          <el-divider></el-divider>
                          <el-form label-width="100px" ref="form">
                            <el-form-item :label="$t('device.data_upload_interval')">
                              <el-input v-model="item.value"></el-input>
                            </el-form-item>
                          </el-form>
                        </template>
                        <template v-else-if="item.id===2">
                          <p>{{ $t('device.wifi_note') }}</p>
                          <el-divider></el-divider>
                          <el-form-item :label="$t('device.position_mode')" label-width="120px">
                            <el-radio-group v-model="item.value">
                              <el-radio :label="1">{{ $t('device.wifi_priority') }}</el-radio>
                              <el-radio :label="2">{{ $t('device.only_gps') }}</el-radio>
                            </el-radio-group>
                          </el-form-item>
                        </template>
                        <el-divider></el-divider>
                        <el-button @click="handleSendCommand(index)" type="primary">{{ $t('device.send') }}</el-button>
                      </el-tab-pane>
                    </template>
                  </el-tabs>
                </el-dialog>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import clients from "@/model/client";
import device from "@/model/device";
import client from "../../model/client";
import {VueCropper} from "vue-cropper";
import "vue-cropper/dist/index.css";
import tool from "@/utils/tool";

// sync
export default {
  name: "home",
  components: {
    VueCropper
  },
  data() {
    return {
      activeName: "first",
      deviceTotal: 0,
      page: 1,
      size: 10,
      userTotal: 0,
      formInline: {
        imei: "",
        client_id: [],
        is_online: "",
        type: "",
        page: 1,
        size: 10
      },
      deviceType: [],
      clientData: [],
      deviceData: [],
      userData: [],
      addDialog: false,
      batchDialog: false,
      batchTip: false,
      allotDialog: false,
      addform: {
        imei: "",
        type_id: ""
      },
      batchform: {
        imei_list: "",
        type_id: ""
      },
      allotform: {
        imei: [],
        client: ""
      },
      userId: "",
      defaultProps: {
        children: "children",
        label: "name"
      },
      ishover: false,
      errorNum: 0,
      successNum: 0,
      filePath: "",
      setnbDialog: false,
      setsgDialog: false,
      set4GLocatorDialog: false,
      setWIFIWatchDialog: false,
      locatorDialog: false,
      heartDialog: false,
      bracelet01G1Dialog: false,
      resetUserDialog: false,
      setNbForm: {
        location: "",
        time: ""
      },
      tabPosition: "left",
      setting: {},
      command: {
        imei: "",
        type: ""
      },
      clientForm: {
        id: "",
        parent_id: "",
        name: "",
        pre_parent_id: "",
        logo: "",
        push_status: 0,
        push_production: '',
        push_testing: ''
      },
      ClientUserForm: {
        client_id: "",
        account: "",
        password: ""
      },
      rightMenuShow: false,
      addUserDialog: false,
      addClientDialog: false,
      resetForm: {
        uid: "",
        password: ""
      },
      options: [],
      loading: false,
      orderParam: {
        prop: "",
        order: ""
      },
      isPreview: false,
      dialogVisible: false,
      logoDialog: false,
      fileLists: [],
      previewImg: "",
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 400, // 默认生成截图框宽度
        autoCropHeight: 120, // 默认生成截图框高度
        maxImgSize: 400,
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loadings: false,
      hideUpload: false,
      filename: "",
      clientShow: false,
      imeiDesabled: false,
      removeImeiArr: [],
      no_result: [],
      is_search: 0,
      search_total: 0,
      noSearchDialog: false,
      excelHeaders: {
        token: tool.getLocalStorage('token')
      },
      locator: {
        imei: ''
      },
      clientFilterText: ''
    };
  },
  watch: {
    clientFilterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  async mounted() {
    await this.clientList();
    await this.devicedList();
    // await this.userList();
    await this.deviceTypeArr();
    document.addEventListener("click", e => {
      const userCon = document.getElementById("rightMenu");
      if (userCon && !userCon.contains(e.target)) {
        this.rightMenuShow = false;
      }
    });
  },
  methods: {
    onover(id) {
      this.ishover = id;
    },
    onleave() {
      this.ishover = false;
    },
    sortChange(column) {
      this.orderParam.prop = 'device.' + column.prop;
      this.orderParam.order = column.order;
      this.devicedList();
    },
    async clientList() {
      const ret = await clients.list();
      if (ret.code === 200) {
        this.clientData = ret.data;
        this.options = this.handleClientTree(ret.data)
      }
    },
    handleClientTree(data) {
      if (data.length === 0) {
        return []
      }
      let options = []
      data.forEach(item => {
        options.push({
          value: item.id,
          label: item.name
        })
        if (item.children.length > 0) {
          options.push({
            value: item.id,
            label: item.name,
            children: this.handleClientTree(item.children)
          })
        }
      })
      return options
    },
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    async devicedList() {
      const ret = await device.list({
        imei: this.formInline.imei,
        client_id: this.formInline.client_id.join(","),
        type: this.formInline.type,
        page: this.formInline.page,
        size: this.formInline.size,
        is_online: this.formInline.is_online,
        order: this.orderParam
      });
      if (ret.code === 200) {
        this.deviceData = ret.data.list;
        this.deviceTotal = ret.data.count;
        this.no_result = ret.data.no_result;
        this.is_search = ret.data.is_search;
        this.search_total = ret.data.search_total;
      }
    },
    async userList() {
      const ret = await clients.userList({
        client_id: "",
        page: this.page,
        size: this.size
      });
      if (ret.code === 200) {
        this.userData = ret.data.list;
        this.userTotal = ret.data.count;
      }
    },
    async deviceAdd() {
      const ret = await device.upload(this.addform);
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.addDialog = false;
        this.devicedList();
      } else {
        this.$message.error(ret.message);
      }
    },
    handleExcelSuccess(response) {
      // const ret = await device.batchUpload(this.batchform);
      this.loading = false;
      if (response.code === 200) {
        this.successNum = response.data.success;
        this.errorNum = response.data.error;
        this.batchDialog = false;
        this.devicedList();
        this.filePath = response.data.file;
        this.batchTip = true;
      } else {
        this.$message.error(response.message);
      }
    },
    // 删除管理员账号
    async delUser() {
      const ret = await clients.userRemove({
        uid: this.userId
      });
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.userList();
      } else {
        this.$message.error(ret.message);
      }
    },
    //新增/编辑客户
    async addClient() {
      const ret = await clients.operate(this.clientForm);
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.addClientDialog = false;
        this.clientList();
      } else {
        this.$message.error(ret.message);
      }
    },
    async resetUserConfirm() {
      const ret = await clients.resetUser(this.resetForm);
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.resetUserDialog = false;
        this.clientList();
      } else {
        this.$message.error(ret.message);
      }
    },
    handleEdit(uid) {
      this.resetForm.uid = uid;
      this.resetUserDialog = true;
    },
    async delClient() {
      const ret = await clients.remove({
        uid: this.userId
      });
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.userList();
      } else {
        this.$message.error(ret.message);
      }
    },
    //新增客户管理员
    async addClientUser() {
      const ret = await clients.user(this.ClientUserForm);
      if (ret.code === 200) {
        this.$message.success(ret.message);
        this.addUserDialog = false;
        this.userList();
      } else {
        this.$message.error(ret.message);
      }
    },
    async handleSendCommand(index) {
      let setting = {}
      if ([12, 16, 18, 19, 20].indexOf(this.command.type) > -1) {
        setting = {id: this.setting[index].id, value: this.setting[index]}
      } else {
        setting = index < 0 ? {id: 99, value: this.setting} : this.setting[index];
      }
      const ret = await device.saveSetting({
        imei: this.command.imei,
        type: this.command.type,
        id: setting.id,
        value: setting.value
      });
      if (ret.code === 200) {
        this.setnbDialog = false;
        this.setsgDialog = false;
        this.set4GLocatorDialog = false;
        this.setWIFIWatchDialog = false;
        this.locatorDialog = false;
        this.heartDialog = false;
        this.bracelet01G1Dialog = false;
        this.$message.success(ret.message);
      } else {
        this.$message.error(ret.message);
      }
    },
    handleNodeClick(node, nodes) {
      if (node.children.length !== 0) {
        if (nodes.checkedKeys.includes(node.id)) {
          this.handleCheckNode(node);
        } else {
          this.handleUnCheckNode(node);
        }
      }
      setTimeout(() => {
        this.formInline.client_id = this.$refs.tree.getCheckedKeys();
        this.devicedList();
      }, 1000);
    },
    handleCheckNode(nodes) {
      for (let i = 0; i < nodes.children.length; i++) {
        this.$refs.tree.setChecked(nodes.children[i], true);
        if (
            nodes.children[i].children &&
            nodes.children[i].children.length > 0
        ) {
          this.handleCheckNode(nodes.children[i]);
        }
      }
    },
    handleUnCheckNode(nodes) {
      for (let i = 0; i < nodes.children.length; i++) {
        this.$refs.tree.setChecked(nodes.children[i], false);
        if (
            nodes.children[i].children &&
            nodes.children[i].children.length > 0
        ) {
          this.handleUnCheckNode(nodes.children[i]);
        }
      }
    },
    handleSelect(selection) {
      this.allotform.imei = [];
      this.removeImeiArr = [];
      selection.forEach(v => {
        this.allotform.imei.push(v.imei);
        this.removeImeiArr.push(v.imei);
      });
    },
    async handleAllot() {
      const ret = await device.allot({
        imei_list: this.allotform.imei.join(","),
        client_id: this.allotform.client[this.allotform.client.length - 1]
      });
      if (ret.code === 200) {
        this.allotDialog = false;
        this.devicedList();
        this.$message.success(this.$t('device.allocate_success'));
      } else {
        this.$message.error(ret.message);
      }
    },
    // 搜索
    devicedListSearch() {
      this.devicedList();
    },
    // 导出
    async handleExport() {
      const ret = await device.export({
        imei: this.formInline.imei,
        client_id: this.formInline.client_id.join(","),
        type: this.formInline.type,
        page: this.formInline.page,
        size: this.formInline.size,
        order: this.orderParam
      });
      if (ret.code === 200) {
        window.open(ret.data);
      }
    },
    // 重置
    handleReset() {
      this.formInline.imei = "";
      this.formInline.type = "";
      this.formInline.is_online = "";
      this.devicedList();
    },
    // 继续查找
    keepSerach() {
      this.is_search = 0;
      this.no_result = [];
      this.formInline.imei = "";
      this.formInline.type = "";
      this.formInline.is_online = "";
      this.noSearchDialog = false;
      this.devicedList();
    },
    // 复制全部
    copyImei() {
      var inputTest = document.createElement("input");
      inputTest.value = this.no_result.join(",");
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = "oInput";
      inputTest.style.display = "none";
      this.$message.success(this.$t('device.replicating'));
      this.noSearchDialog = false;
    },
    addDevice() {
      this.imeiDesabled = false;
      this.addDialog = true;
    },
    addConfirm() {
      this.deviceAdd();
    },
    batchAddDevice() {
      this.batchDialog = true;
    },
    batchAddConfirm() {
      this.loading = true;
      this.$refs.excel.submit()
    },
    allotClient() {
      (this.allotform.client = ""), (this.allotDialog = true);
    },
    // 批量删除
    batchDel() {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            const ret = await device.batchRemove({
              imei: this.removeImeiArr
            });
            if (ret.code === 200) {
              this.$message.success(this.$t('device.delete_success'));
              this.devicedList();
            } else {
              this.$message.error(ret.message);
              this.devicedList();
            }
          })
          .catch(() => {
          });
    },
    userDel(id) {
      this.userId = id;
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            this.delUser();
          })
          .catch(() => {
          });
    },
    // 参数
    async setParam(data) {
      if ([1, 6, 7, 13, 14, 21].indexOf(data.type_id) > -1) {
        this.setsgDialog = true;
      } else if (data.type_id === 12 || data.type_id === 20) {
        this.locatorDialog = true;
      } else if (data.type_id === 15) {
        this.set4GLocatorDialog = true;
      } else if (data.type_id === 17) {
        this.setWIFIWatchDialog = true;
      } else if (data.type_id === 16) {
        this.heartDialog = true;
      } else if (data.type_id === 18 || data.type_id === 19) {
        this.bracelet01G1Dialog = true;
      } else {
        this.setnbDialog = true;
      }
      this.command = {
        imei: data.imei,
        type: data.type_id
      };
      const ret = await device.setting(this.command);
      if (ret.code === 200) {
        this.setting = ret.data;
      }
    },
    // 编辑
    async editParam(data) {
      this.imeiDesabled = true;
      this.addform.imei = data.imei;
      this.addform.type_id = data.type_id;
      this.addDialog = true;
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.devicedList();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.devicedList();
    },
    handleSizeChanges(val) {
      this.size = val;
      this.userList();
    },
    handleCurrentChanges(val) {
      this.page = val;
      this.userList();
    },
    // 新增客户
    newIncrease(node) {
      this.clientShow = true;
      this.clientForm.id = "";
      this.clientForm.name = "";
      this.clientForm.logo = "";
      this.clientForm.push_status = 0;
      this.clientForm.push_testing = "";
      this.clientForm.push_production = "";
      this.fileLists.length = [];
      this.hideUpload = false;
      this.clientForm.parent_id = node.data.id;
      this.clientForm.pre_parent_id = node.data.parent_id;
      this.ClientUserForm.client_id = node.data.id;
      this.clientForm.topClient = node.data.name;
      this.addClientDialog = true;
    },
    // 新增客户
    addClientConfirm() {
      if (this.clientForm.logo == "") {
        this.clientForm.logo = "https://adminapi.yztc2022.com/logo.png";
        this.fileLists.length = [];
        this.fileLists.push({
          name: "logo.png",
          url: "https://adminapi.yztc2022.com/logo.png"
        });
      }
      this.addClient();
    },
    // 编辑客户
    editClient(node) {
      this.clientShow = false;
      this.clientForm.name = node.data.name;
      this.clientForm.logo = node.data.logo;
      this.fileLists.length = [];
      this.fileLists.push({name: "logo.png", url: node.data.logo});

      this.hideUpload = true;
      this.clientForm.id = node.data.id;
      this.clientForm.parent_id = node.data.parent_id;
      this.clientForm.push_status = node.data.push_status;
      this.clientForm.push_testing = node.data.push_testing;
      this.clientForm.push_production = node.data.push_production;
      this.addClientDialog = true;
    },
    // 删除左侧客户
    deleteData(node) {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            const ret = await client.remove({id: node.data.id});
            if (ret.code === 200) {
              this.clientList();
              this.$message.success(this.$t('device.delete_success'));
            } else {
              this.$message.error(ret.message);
            }
          })
          .catch(() => {
          });
    },
    // eslint-disable-next-line no-unused-vars
    addUserConfirm() {
      this.addClientUser();
    },
    // eslint-disable-next-line no-unused-vars
    handleChangeUpload(file, fileList) {
      this.hideUpload = true;
      if (file.status !== "ready") return;
      const isJPG =
          file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(this.$t('device.upload_error'));
        return false;
      }
      if (!isLt2M) {
        this.$message.error(this.$t('device.upload_note'));
        return false;
      }
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(async () => {
        // this.previewImg = URL.createObjectURL(file.raw);
        this.filename = file.name;
        this.option.img = URL.createObjectURL(file.raw);
        this.loadings = false;
        this.addClientDialog = false;
        this.dialogVisible = true;
      });
    },
    finish() {
      // 获取截图的 blob 数据
      this.fileLists = [];
      this.$refs.cropper.getCropBlob(async blob => {
        this.loadings = true;
        this.previewImg = URL.createObjectURL(blob);
        this.option.img = URL.createObjectURL(blob);
        let formData = new FormData();
        formData.append("file", blob, this.filename);
        const ret = await device.uploadFile(formData);
        if (ret.code === 200) {
          this.clientForm.logo = ret.data;
          this.fileLists.length = [];
          this.fileLists.push({name: "logo.png", url: ret.data});
          this.$message.success(this.$t('device.upload_success'));
          this.dialogVisible = false;
          this.addClientDialog = true;
        }
      });
    },
    // 图片查看大图
    handlePictureCardPreview(file) {
      this.clientForm.logo = file.url;
      this.logoDialog = true;
    },
    // 删除图片
    // eslint-disable-next-line no-unused-vars
    handleRemove(file, fileList) {
      // const index = (this.clientForm.logo = "");
      this.fileLists.splice(0, 1);
      this.hideUpload = false;
    },
    onError() {
      this.$message.success(this.$t('device.upload_failed'));
    },
    clientFilterNode(value, data) {
      if (!value) return true
      return data.name.includes(value)
    }
  }
};
</script>
<style scoped>
#app {
  position: relative;
}

.el-scrollbar {
  overflow: auto !important;
}

.el-scrollbar .el-scrollbar_wrap {
  /* overflow-x: hidden !important; */
  height: 600px !important;
}

.coll-1 .el-collapse-item__content {
  height: calc(80vh - 300px);
  overflow: auto;
}

#clientDiv .el-tree {
  display: inline-block !important;
}

.custom-tree-node {
  display: flex;
  font-size: 14px;
}

.action_section {
  right: 40px;
  width: 128px;
}

.action_section span {
  display: inline-block;
  margin-left: 12px;
}

.demo-form-inline {
  margin-top: 20px;
}

.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-table {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}

h2 {
  height: 50px;
  text-indent: 20px;
  line-height: 50px;
}

.warnmes {
  height: 40px;
  line-height: 40px;
  background: #f2f2f2;
  color: red;
  text-indent: 20px;
  margin-bottom: 10px;
}

.el-tab-pane h4 {
  font-size: 24px;
  line-height: 50px;
}

#clientDiv {
  position: relative;
}

.hide >>> .el-upload--picture-card {
  display: none;
}

/* .upload-demo /deep/ .el-upload-dragger {
  width: 200px !important;
  height: 48px !important;
  text-align: center;
  line-height: 48px !important;
}

.upload-demo .el-icon {
  margin-top: 10px;
} */
.el-upload-list--picture-card .el-upload-list__item {
  width: 400px !important;
  height: 120px !important;
}

.el-upload-list__item-actions {
  width: 400px !important;
  height: 120px !important;
}

.cropper-content .cropper {
  width: auto;
  height: 350px;
}

.upload-img /deep/ .el-upload-list__item {
  width: 100% !important;
  height: auto !important;
}

.upload-img /deep/ .el-upload--picture-card {
  width: 400px;
  height: 120px;
  line-height: 120px;
}

.upload-img /deep/ .el-upload--picture-card i {
  margin-top: 47px !important;
}

.upload-img /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 400px !important;
  height: 120px !important;
}
</style>

